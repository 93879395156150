export default [
    {
        path: '/wallet',
        name: 'wallet',
        component: (): Promise<any>  => import('@/pages/Wallet.vue'),
        meta: {
            needAuth: true,
        },
    },
];
