export default [
    {
        path: '/withdraw',
        name: 'withdraw',
        component: (): Promise<any> => import('@/pages/Withdraw.vue'),
        meta: {
            needAuth: true,
        },
    },
];
