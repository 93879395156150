// TOKEN CREDENTIAL
const tokenKey = `${process.env.NODE_ENV}_access_token`;
const expiredTokenKey = `${process.env.NODE_ENV}_expired_token`;
const isAdminKey = `${process.env.NODE_ENV}_is_admin`;

class Storage {
  getToken() {
    return localStorage.getItem(tokenKey)
  }

  getExpiredToken() {
    const expiredToken = localStorage.getItem(expiredTokenKey)
    const formatExpireToken = new Date(parseInt(expiredToken || "") * 1000);
    const currentdate = new Date();
    const datetime = currentdate.getDate();

    return datetime < formatExpireToken.getDate()
  }

  getIsAdmin() {
    return localStorage.getItem(isAdminKey) === "true"
  }

  setCredential({ token, expired, isAdmin }: any) {
    localStorage.setItem(tokenKey, token);
    localStorage.setItem(expiredTokenKey, expired);
    localStorage.setItem(isAdminKey, isAdmin)
  }

  removeAuthCredential() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expiredTokenKey);
    localStorage.removeItem(isAdminKey);
  }
}

export default new Storage()