export default [
    {
        path: '/login',
        name: 'login',
        component: (): Promise<any>  => import('@/pages/Login.vue'),
        meta: {
            needAuth: false,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: (): Promise<any>  => import('@/pages/Register.vue'),
        meta: {
            needAuth: false,
        },
    },
];
