import VueSweetalert2 from 'sweetalert2';

class Alert {
  Error({ title, message }: any) {
    VueSweetalert2.fire({
      title: title,
      text: message,
      icon: 'error'
    })
  }

  Success({ title, message }: any) {
    VueSweetalert2.fire({
      title: title,
      text: message,
      icon: 'success'
    })
  }

  Toast({ type, message, width, position }: any) {
    const Toast = VueSweetalert2.mixin({
      toast: true,
      position: position,
      showConfirmButton: false,
      timer: 2000,
      width: width,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', VueSweetalert2.stopTimer)
        toast.addEventListener('mouseleave', VueSweetalert2.resumeTimer)
      }
    })

    Toast.fire({
      icon: type,
      title: message
    })
  }

  async Confirmation({ type, btnTxt, title }: any): Promise<boolean> {
    let isConfirmed = false
    await VueSweetalert2.fire({
      title: title,
      icon: type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: btnTxt
    }).then((result) => {
      if (result.isConfirmed) {
        isConfirmed = true
        return isConfirmed
      }
    })

    return isConfirmed
  }

  async ConfirmationFormFile(): Promise<any> {
    let files = undefined
    await VueSweetalert2.fire({
      title: 'Input Proof File',
      input: 'file',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      showLoaderOnConfirm: true,
      preConfirm: (file) => {
        try {
          if (!file) {
            throw new Error("File tidak boleh kosong")
          }else{
            files = file
          }
          return
        }
        catch (error) {
          VueSweetalert2.showValidationMessage(
            `${error}`
          )
        }
      },
      allowOutsideClick: () => !VueSweetalert2.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        return result.value
      }
    })

    return files
  }

  Loading() {
    VueSweetalert2.showLoading()
  }
  
  Close(){
    VueSweetalert2.close()
  }
}

export default new Alert()
