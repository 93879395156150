export default [
    {
        path: '/bulk-register',
        name: 'Bulk Register',
        component: (): Promise<any> => import('@/pages/BulkRegister.vue'),
        meta: {
            needAuth: true,
        },
    },
];
