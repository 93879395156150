import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Storage from "@/misc/Storage";
import Alert from "@/misc/Alert";
import AuthenticationModules from "./modules/authentication";
import DefaultsModules from "./modules/defaults";
import WithdrawModules from "./modules/withdraw";
import TrasactionsModules from "./modules/transactions";
import WalletModules from "./modules/wallet";
import User from "./modules/user";

const routes: Array<RouteRecordRaw> = [
  ...AuthenticationModules,
  {
    path: "/",
    component: () => import("@/layouts/MainLayout.vue"),
    children: [
      ...WithdrawModules,
      ...TrasactionsModules,
      ...WalletModules,
      ...User
    ],
    meta: {
      needAuth: true,
    },
  },
  ...DefaultsModules,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    function checkAuth() {
      const accessToken = Storage.getToken();
      const expiredToken = Storage.getExpiredToken();
      return accessToken && expiredToken;
    }

    const isAuth = checkAuth();
    if (to.meta.needAuth) {
      if (isAuth) {
        next();
        return;
      }
      Storage.removeAuthCredential();
      Alert.Toast({
        type: "error",
        message: "Session or Credential is Invalid",
        width: 400,
        position: "top",
      });
      next({
        path: "/login",
      });
      return;
    }

    next();
  }
);

export default router;
