export default [
    {
        path: '/transactions',
        name: 'transactions',
        component: (): Promise<any> => import('@/pages/Transactions.vue'),
        meta: {
            needAuth: true,
        },
    },
];
